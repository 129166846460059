<template>
  <div class='kol-mapper'>

    <div class='default-table'>
      <el-row :gutter='10' style='margin-bottom: 10px;text-align: left'>
        <el-col :span='1.5'>
          <EcpDeptSelect class='select-custom' ref='ecp_dept'
                         @handleSelect='selectDept'/>
          <!--          <DeptCascaderScope-->
          <!--              :showFirstGroup="false" :type="{type:'ecp_bonus_data'}" ref="refDeptCascaderScope"-->
          <!--              :show-first-group="true"-->
          <!--              :selectUrl="'optionEcpBonusMember'" style="display: inline-block"-->
          <!--              @selectedDept="selectDept"/>-->
          <!--          <DeptCascader ref="refDeptCascader" :multiple="true" :check-strictly="false" :collapse-tags="true"-->
          <!--                     :selectUrl="'optionEcpBonusMember'"   :size="'small'" :emit-path="false" @change="selectDept"/>-->
        </el-col>
        <el-col :span="1.5">
          <el-select v-model="searchCond.artist_id" placeholder="请选择" clearable @change="getList">
            <el-option
                v-for="item in kolList"
                :key="item.artist_id"
                :label="item.nickname"
                :value="item.artist_id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span='1.5'>
        <el-button type='primary' @click='getList' icon='el-icon-refresh'
                   v-hasAuthority="'ecp_kol_mapper_list'">刷新
        </el-button>
      </el-col>
        <el-col :span='1.5'>
          <el-button type='primary' @click='handleAdd' icon='el-icon-plus'
                     v-hasAuthority="'ecp_kol_mapper_create'">添加
          </el-button>
        </el-col>
        <el-col :span='1.5'>
          <el-button type='danger' icon='el-icon-delete' :disabled='multiple' @click='handleRemoveChecked'
                     v-hasAuthority="'ecp_kol_mapper_delete'">删除
          </el-button>
        </el-col>

        <el-col :span="1.5">
          <el-button type='primary' v-hasAuthority="'ecp_kol_mapper_export'"
                     icon="el-icon-download" v-loading="downloadLoading" @click='exportExcel'>导出
          </el-button>
        </el-col>
      </el-row>
      <div style="width: 1390px">

        <el-table border :data='dataList'
                  @selection-change='handleSelectionChange'
                  v-loading="downloadLoading" @sort-change='changeTableSort'
                  style='width: 100%'>
          <el-table-column
              type='selection'
              width='55'>
          </el-table-column>
          <el-table-column type='index' label='序号' align='center' width='55'></el-table-column>
          <!--        <el-table-column prop='id' label='ID' align='center' width='100'></el-table-column>-->
          <el-table-column prop='nickname' label='红人' align='center' min-width='190'
                           sortable='custom'></el-table-column>
          <el-table-column label='项目组长/副组长' prop='leader_name' min-width="160" align='center'
                           sortable='custom'></el-table-column>
          <el-table-column label='项目组' prop='group_name' width='260' align='center' sortable='custom'>
            <template slot-scope='{row}'>
              <span>{{ row.dept_name || '-' }}/{{ row.group_name || '' }}</span>
            </template>
          </el-table-column>
          <el-table-column label='项目组负责人' prop='m_leader' width='160' align='center'
                           sortable='custom'></el-table-column>
          <el-table-column label='创建时间' prop='created_at' width='160' align='center'
                           sortable='custom'></el-table-column>
          <el-table-column label='修改时间' prop='updated_at' width='160' align='center'
                           sortable='custom'></el-table-column>
          <el-table-column label='操作' align='center' width='180'>
            <template slot-scope='{row,$index}'>
              <el-button type='text' @click='handleEdit(row)' icon='el-icon-edit'
                         v-hasAuthority="'ecp_kol_mapper_edit'">修改
              </el-button>
              <!--            <el-button type='text' @click='handleCopy(row)' icon='el-icon-document-copy'>复制</el-button>-->
              <el-button type='text' @click='handleRemove(row,$index)' icon='el-icon-remove'
                         v-hasAuthority="'ecp_kol_mapper_delete'">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--      分页-->
        <pagination :total.sync='total' :limit.sync='pageData.page_size' :page.sync='pageData.current_page'
                    @pagination='getList'/>

      </div>
    </div>

    <!--    添加  -->
    <KolMapperCreateEdit v-if="dialogVisible" :visible.sync='dialogVisible' :id='currentId' @saved='getList' />

  </div>
</template>

<script>
import KolMapperCreateEdit from '@/pages/commerce/kol_mapper/KolMapperCreateEdit'
import * as XLSX from 'xlsx-js-style'
import EcpDeptSelect from '@/pages/commerce/kol_mapper/EcpDeptSelect'

export default {
  name: 'KolMapperList',
  components: { KolMapperCreateEdit, EcpDeptSelect },
  data() {
    return {
      currentId: null,
      dialogVisible: false,
      dataList: [],
      pageData: { page_size: 15, current_page: 1 },
      total: 0,
      orderSort: { id: 'desc' },
      selections: [],
      // 非多个禁用
      multiple: true,
      searchCond: {},
      kolList: [],
      downDataList: [],
      downloadLoading: false
    }
  },
  methods: {
    // 下载表格
    exportExcel: function() {
      this.downloadLoading = true
      this.getAllData()

    },
    async getAllData() {
      let pageData = {
        page_size: this.pageData.page_size,
        current_page: 1,
        total: this.total
      }

      await this.getDownList(pageData)
      if (this.downDataList.length === this.total) {
        // const xlsxParam = { raw: false } //转换成excel时，使用原始的格式
        /* 生成工作表 */
        const workbook = XLSX.utils.book_new()
        let header = ['序号', '红人', '项目组长/副组长', '项目组', '项目组负责人', '创建时间', '修改时间']
        if (this.downDataList.length === this.total) {
          let data = this.downDataList.map((item, i) => {
            return {
              '序号': i + 1,
              '项目组长/副组长': item.leader_name,
              '项目组': item.group_name,
              '项目组负责人': item.m_leader,
              '创建时间': item.created_at,
              '修改时间': item.updated_at,
              '红人': item.nickname
            }
          })
          let ws = XLSX.utils.json_to_sheet(data, { header: header })
          ws = this.worksheetStyle(ws)
          XLSX.utils.book_append_sheet(workbook, ws, '红人关联表')
          XLSX.writeFile(workbook, `红人关联表.xlsx`)
          this.downloadLoading = false

        }

      }

    },
    async getDownList(pageData) {
      let searchCond = { ...this.searchCond }
      searchCond?.artist_id === '' ? delete searchCond.artist_id : ''
      searchCond?.groupIds?.length === 0 ? delete searchCond.groupIds : ''
      let {
        list,
        pages
      } = await this.$api.getKolMapperList(Object.assign(searchCond, pageData))
      this.downDataList = [...this.downDataList, ...list]
      pageData.total = pages.total
      pageData.page_size = pages.page_size
      pageData.current_page = pages.current_page

      if (this.downDataList.length < pageData.total) {
        pageData.current_page++
        await this.getDownList(pageData)
      } else {
        return false
      }
    },
    /* 设置工作簿样式 */
    worksheetStyle: function(worksheet) {
      // 表样式设置
      const colsLength = 14
      let cols = [] // 设置每列的宽度
      // wpx 字段表示以像素为单位，wch 字段表示以字符为单位
      for (let i = 0; i <= colsLength; i++) {
        let col = {}
        if (i == 0) {
          col.wch = 20
        } else {
          col.wch = 18
        }
        cols.push(col)
      }
      worksheet['!cols'] = cols // 设置列宽信息到工作表
      //以下是样式设置，样式设置放在组织完数据之后，xlsx-js-style的核心API就是SheetJS的
      Object.keys(worksheet).forEach(key => {

        // 非!开头的属性都是单元格
        if (!key.startsWith('!')) {
          if (key.indexOf('A') != -1 || ['B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1', 'M1', 'N1'].includes(key)) {
            if (key.indexOf('A') != -1) {
              if (key === 'A1') {
                worksheet[key].s = {
                  font: {
                    sz: '12',
                    name: 'Microsoft YaHei',
                    color: { rgb: 'FFFFFF' }
                  },
                  alignment: {
                    horizontal: 'center',
                    vertical: 'center',
                    wrapText: true
                  },
                  border: {
                    top: { style: 'thin' },
                    right: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' }
                  },
                  fill: {
                    fgColor: { rgb: '62d2ae' }
                  }
                }
              } else
                worksheet[key].s = {
                  font: {
                    sz: '12',
                    name: 'Microsoft YaHei'
                  },
                  alignment: {
                    horizontal: 'center',
                    vertical: 'center',
                    wrapText: true
                  },
                  border: {
                    top: { style: 'thin' },
                    right: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' }
                  }
                }
            } else {
              worksheet[key].s = {
                font: {
                  sz: '12',
                  color: { rgb: 'FFFFFF' },
                  name: 'Microsoft YaHei'
                },
                alignment: {
                  horizontal: 'center',
                  vertical: 'center',
                  wrapText: true
                },
                border: {
                  top: { style: 'thin' },
                  right: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' }
                },
                fill: {
                  fgColor: { rgb: '62d2ae' }
                }
              }
            }
            worksheet[key].t = 's'
          } else {
            worksheet[key].s = {
              font: {
                sz: '14'
              },
              alignment: {
                horizontal: 'right',
                vertical: 'right',
                wrapText: true
              },
              border: {
                top: { style: 'thin' },
                right: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' }
              }
            }
            worksheet[key].t = 'n'
            worksheet[key].s.numFmt = 2
          }
        }

      })

      return worksheet
    },
    selectDept(arr) {
      if (arr && arr.length > 0) {
        let group_id = null
        group_id = [arr[arr.length - 1].dpt_id, arr[arr.length - 1].parent_id]
        this.searchCond.groupIds = group_id
      } else this.searchCond.groupIds = null
      this.getList()
    },

    // async handleCopy(row) {
    //   delete row.id
    //   await this.$api.saveKolMapper(row)
    //   await this.getList()
    // },
    handleEdit(row) {
      this.currentId = row.id
      this.dialogVisible = true
    },
    handleAdd() {
      this.currentId = null
      this.dialogVisible = true
    },
    //加载列表
    async getList() {
      let searchCond = { ...this.searchCond }
      searchCond?.artist_id === '' ? delete searchCond.artist_id : ''
      searchCond?.groupIds?.length===0 ? delete searchCond.groupIds : ''

      let {
        list,
        pages
      } = await this.$api.getKolMapperList(Object.assign(searchCond, this.pageData, { order: this.orderSort }))

      this.dataList = list
      this.total = pages.total
      this.pageData.page_size = pages.page_size
      this.pageData.current_page = pages.current_page
    },
    async getKolList() {
      let data = await this.$api.getKolMapperSearch()
      this.kolList = data
    },
    async delRow(id) {
      let info = await this.$api.deleteKolMapper(id)
      if (info) {
        this.msgSuccess('删除成功')
      }
    },
    handleRemove(row, $index) {
      this.$confirm(`确认删除?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.dataList.splice($index, 1)
        await this.delRow(row.id)
        await this.getList()
      })
    },
    handleRemoveChecked() {
      let msg = `确认删除勾选的数据[${this.selections.length}]条？`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {

        this.selections.forEach((_) => {
          this.delRow(_.id)
        })
        setTimeout(async () => {
          await this.getList()
        }, 200)
      })
    },
    cacheData() {
      localStorage.setItem('kol_mappers', JSON.stringify(this.dataList))
    },
    getCache() {
      let dataListStr = localStorage.getItem('kol_mappers')
      this.dataList = dataListStr ? JSON.parse(dataListStr) : []
    },
    handleSelectionChange(selection) {
      this.selections = selection
      this.multiple = !selection.length
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()
    }
  },
  created() {
    this.getList()
    this.getKolList()
  }
}
</script>

<style scoped>
.kol-mapper {
  padding: 10px;
  margin: auto;
  /*border: #00feff 1px dashed;*/
  width: 100%;
  text-align: center;
}
</style>
